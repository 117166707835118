import { http, HttpResponse } from "msw";
import promotions from "../mocks/personal-promotions.json";

export default [
  http.get("/webgate/v1/personal-offers/personal-promotions", () => {
    return HttpResponse.json(promotions, { status: 200 });
  }),
  http.get(
    "/webgate/v1/personal-offers/personal-promotions/:code",
    ({ params }) => {
      const promotion = promotions.items.find(
        (item) => item.code === params.code,
      );

      return promotion
        ? HttpResponse.json(promotion, { status: 200 })
        : new HttpResponse(null, { status: 422 });
    },
  ),
];
